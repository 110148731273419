

























import { EventSession } from "@/models/event-session.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment";

@Component({ components: {} })
export default class CalendarItem extends Vue {
  isLoading = false;
  @Prop()
  eventSession!: EventSession;

  get locale() {
    return this.$root.$i18n.locale;
  }

  get day() {
    return moment(this.eventSession.start[this.locale]).format("DD");
  }

  get month() {
    return moment(this.eventSession.start[this.locale]).format("MMM");
  }

  get startTime() {
    return moment(this.eventSession.start[this.locale]).format("HH:mm");
  }

  get url() {
    return this.eventSession.url[this.locale];
  }
  get urlTarget() {
    return this.eventSession.target;
  }
}
