<template>
  <b-container class="registration-card-container">
    <registration-form
      :config="formConfig.steps"
      :disabled="isDisabled"
      v-model="registrationData"
      @submit="doSubmit"
    />
  </b-container>
</template>

<script>
import RegistrationForm from "@/components/BaseForm.vue";

export default {
  props: ["form"],
  components: { RegistrationForm },
  data() {
    return {
      isLoading: false,
      registrationData: {},
      isDisabled: false,
    };
  },
  computed: {
    formConfig() {
      return this.$store.getters["Registration/form"];
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
  methods: {
    async doSubmit() {
      this.isLoading = true;
      await this.$store.dispatch(
        "Auth/addFormRegistration",
        this.registrationData
      );
      if (this.registrationData.complete.length > 0) {
        this.isDisabled = true;
      }
      this.isLoading = false;
      this.$router.push({ path: this.formConfig.link });
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch(
      "Registration/fetchById",
      "form" + this.form.selectedForm
    );
    if (
      this.currentUser["additional_info_form" + this.form.selectedForm] !== null
    ) {
      console.log("test");
      console.log(
        this.currentUser["additional_info_form" + this.form.selectedForm]
      );
      this.registrationData = this.currentUser[
        "additional_info_form" + this.form.selectedForm
      ];
    }
    this.registrationData.form = this.form.selectedForm;
    if (
      this.registrationData.complete &&
      this.registrationData.complete.length > 0
    ) {
      this.isDisabled = true;
    }
    this.isLoading = false;
  },
};
</script>
