





























































import Zoom from "@/components/video/Zoom.vue";
import Youtube from "@/components/video/Youtube.vue";
import Vimeo from "@/components/video/Vimeo.vue";
import Whereby from "@/components/interactive-livestream/Whereby.vue";
import Jitsi from "@/components/interactive-livestream/Jitsi.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const stylingNamespace = namespace("Styling");

@Component({
  components: {
    Vimeo,
    Zoom,
    Youtube,
    Whereby,
    Jitsi,
  },
})
export default class Video extends Vue {
  $refs!: Vue["$refs"] & {
    videoDiv: any;
  };
  livestream: any = {};
  isStuck = false;
  overrideIsStuck = false;
  outerHeightVideo = 700;

  @Prop()
  id!: string;

  @stylingNamespace.Getter("floatingVideo")
  floatingVideoSetting!: boolean;

  get livestreamAvailable() {
    return this.livestream?.id;
  }

  get settings() {
    return this.$store.state.Config.all;
  }

  get locale() {
    return this.$root.$i18n.locale;
  }

  get chatActive() {
    return this.livestream?.chat?.active;
  }

  get livestreamType() {
    return this.livestream?.type[this.locale];
  }

  get livestreamCode() {
    return this.livestream?.code[this.locale];
  }

  get currentUser() {
    return this.$store.getters["Auth/currentUser"];
  }

  get videoHeight() {
    if (this.isStuck) {
      return `${this.outerHeightVideo}px`;
    }
    return "auto";
  }

  get videoClasses() {
    if (this.isStuck) {
      return "video-fv stuck";
    }
    return "video-fv";
  }

  @Watch("id")
  onIdChanged() {
    this.livestream = this.$store.getters["Livestreams/livestream"];
  }

  unstuck() {
    this.overrideIsStuck = true;
    this.isStuck = false;
  }
  async fetchLivestream() {
    // await this.$store.dispatch("Livestreams/fetchById", this.id);
    this.livestream = this.$store.getters["Livestreams/livestream"];
  }

  handleScroll() {
    if (!this.floatingVideoSetting) {
      return;
    }
    var windowScrollTop = window.scrollY;
    const videoHeight = this.outerHeightVideo * 0.75;
    const offsetVideoWrap =
      this.$refs.videoDiv.getBoundingClientRect().top + windowScrollTop;
    const videoBottom = videoHeight + offsetVideoWrap;
    if (windowScrollTop <= videoBottom) {
      this.overrideIsStuck = false;
    }

    if (windowScrollTop > videoBottom && !this.overrideIsStuck) {
      this.isStuck = true;
    } else {
      this.isStuck = false;
    }
  }

  async created() {
    await this.fetchLivestream();
  }

  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  }
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  }
}
