












import { DashboardItem } from "@/models/dashboard-item.model";
import { EventSession } from "@/models/event-session.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CalendarItem from "./CalendarItem.vue";

const eventSessionsModule = namespace("EventSessions");

@Component({ components: { CalendarItem } })
export default class Calendar extends Vue {
  isLoading = false;
  @Prop()
  calendar!: DashboardItem;

  @eventSessionsModule.Getter("all")
  eventSessions!: EventSession[];

  @eventSessionsModule.Action("fetchAll")
  fetchAll!: () => Promise<EventSession[]>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  created() {
    this.doFetchAll();
  }
}
