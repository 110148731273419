<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <h1
        class="header pl-3"
        v-if="
          translate(block.title) &&
            block.active &&
            (block.type.toLowerCase() == 'social' ||
              block.type.toLowerCase() == 'carousel')
        "
      >
        {{ translate(block.title) }}
      </h1>
      <!-- <Schedule v-if="block.type.toLowerCase() == 'schedule' && block.active" /> -->
      <Video
        class="col-lg-12 mb-5"
        v-if="block.type.toLowerCase() == 'livestream' && block.active"
        :id="block.selectedLivestream[locale]"
      />
      <SocialWall
        v-if="block.type.toLowerCase() == 'social' && block.active"
        :topic="block.topic"
      />
      <Banner
        v-if="block.type.toLowerCase() == 'banner' && block.active"
        :banner="block"
      />
      <CardList
        v-if="block.type.toLowerCase() == 'items' && block.active"
        :cardlist="block"
      />
      <Carousel
        v-if="block.type.toLowerCase() == 'carousel' && block.active"
        :carousel="block"
      />
      <Calendar
        v-if="block.type.toLowerCase() == 'calendar' && block.active"
        :calendar="block"
      />
      <TempNextEvent v-if="block.type == 'nextEvent' && block.active" />
      <Form
        v-if="block.type.toLowerCase() == 'form' && block.active"
        :form="block"
      />
      <div
        v-if="block.type.toLowerCase() == 'html' && block.active"
        v-html="block.html[locale]"
      />
    </div>
  </div>
</template>
<script>
import Banner from "@/components/landing/Banner.vue";
import CardList from "@/components/landing/CardList.vue";
import Carousel from "@/components/landing/Carousel.vue";
import Calendar from "@/components/landing/Calendar.vue";
import SocialWall from "@/components/landing/SocialWall.vue";
// import Schedule from "@/components/landing/Schedule.vue";
import Form from "@/components/landing/Form";
import Video from "@/components/video/Video";
import TempNextEvent from "@/components/landing/TempNextEvent.vue";
import { translateSetting } from "@/utils/translated-setting.util";
export default {
  name: "Items",
  props: ["blocks"],
  components: {
    Banner,
    CardList,
    Video,
    Carousel,
    SocialWall,
    // Schedule,
    TempNextEvent,
    Calendar,
    Form,
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    translate(value) {
      return translateSetting(value, this.locale);
    },
  },
};
</script>
